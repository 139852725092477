// extracted by mini-css-extract-plugin
export var blue = "index-module--blue--YJ7Xs";
export var buttonProject = "index-module--buttonProject--TfEY1";
export var buttonSvg = "index-module--buttonSvg--56bPB";
export var green = "index-module--green--EnMHS";
export var indexPage = "index-module--indexPage--7XFax";
export var indexPageWrapper = "index-module--indexPageWrapper--roKOu";
export var indextitle = "index-module--indextitle--86ezH";
export var orange = "index-module--orange--GI1Mz";
export var purple = "index-module--purple--eG5vY";
export var weetjesgenerator = "index-module--weetjesgenerator--jSGZZ";
export var welkom1 = "index-module--welkom1--fo3An";
export var welkom2 = "index-module--welkom2--cmrfM";
export var welkomWrapper = "index-module--welkomWrapper--KrtrC";
export var yellow = "index-module--yellow--ij7gL";