import * as React from "react";
import { Link } from "gatsby";
import Seo from "../components/seo"

import Navigation from "../components/navigation";
import Footer from "../components/footer";
import Weetjesgenerator from "../components/Weetjesgenerator"

import * as styles from "../components/pages/index.module.css"

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef, useLayoutEffect } from "react";
gsap.registerPlugin(ScrollTrigger);


const IndexPage = () => {
  const pageRef = useRef();

  useLayoutEffect(() => {
      let ctx = gsap.context(() => {
      const welkom = document.querySelector("#welkom2");
      const wekomMeasures = welkom.getBoundingClientRect();

      window.addEventListener("mousemove", (e) => {
        const { clientX, clientY } = e;
        const x = Math.round((clientX - wekomMeasures.x));
        const y = Math.round((clientY - wekomMeasures.y));
      
        gsap.to(welkom, {
          "--x": `${x}px`,
          "--y": `${y}px`,
          })
        })
     }, [pageRef]); // <- IMPORTANT! Scopes selector text
    return () => ctx.revert();
  }, []);

  return(
    <div>
      <div className={styles.indexPageWrapper}> 
        <Navigation/>
        
        <main className={styles.indexPage} id="page" ref={pageRef}>
     
          <div className={styles.welkomWrapper}>
            <h1 className={`${styles.indextitle} ${styles.welkom1}`}>
               <span>W</span>
              <span>e</span>
              <span>l</span>
              <span>k</span>
              <span>o</span>
              <span>m</span>
              <span>!</span>
              <span> </span>
              <span>I</span>
              <span>k</span>
              <span> </span>
              <span>b</span>
              <span>e</span>
              <span>n</span>
              <span> </span>
              <span>S</span>
              <span>i</span>
              <span>l</span>
              <span>k</span>
              <span>e</span>
              <span>.</span>
              <span> </span>
              <br></br>
              <span>D</span>
              <span>i</span>
              <span>g</span>
              <span>i</span>
              <span>t</span>
              <span>a</span>
              <span>l</span>
              <span> </span>
              <span>d</span>
              <span>e</span>
              <span>s</span>
              <span>i</span>
              <span>g</span>
              <span>n</span>
              <span>e</span>
              <span>r</span>
              <span>,</span>
              <span> </span>
              <span>c</span>
              <span>r</span>
              <span>e</span>
              <span>a</span>
              <span>t</span>
              <span>i</span>
              <span>e</span>
              <span>v</span>
              <span>e</span>
              <span> </span>
              <span>d</span>
              <span>e</span>
              <span>v</span>
              <span>e</span>
              <span>l</span>
              <span>o</span>
              <span>p</span>
              <span>e</span>
              <span>r</span>
              <span> </span>
              <span>e</span>
              <span>n</span>
              <span> </span>
              <span>b</span>
              <span>o</span>
              <span>v</span>
              <span>en</span>
              <span>a</span>
              <span>l</span>
              <span> </span>
              <span>g</span>
              <span>e</span>
              <span>w</span>
              <span>o</span>
              <span>o</span>
              <span>n</span>
              <span> </span>
              <span>r</span>
              <span>o</span>
              <span>s</span>
              <span> </span>
              <span>en</span>
              <span> </span>
              <span>e</span>
              <span>n</span>
              <span>e</span>
              <span>r</span>
              <span>gi</span>
              <span>e</span>
              <span>k</span>
            </h1>

            <p className={`${styles.indextitle} ${styles.welkom2}`} id="welkom2">
              <span className={styles.orange}>W</span>
              <span className={styles.purple}>e</span>
              <span className={styles.green}>l</span>
              <span className={styles.yellow}>k</span>
              <span className={styles.blue}>o</span>
              <span className={styles.orange}>m</span>
              <span className={styles.purple}>!</span>
              <span className={styles.purple}> </span>
              <span className={styles.orange}>I</span>
              <span className={styles.purple}>k</span>
              <span className={styles.green}> </span>
              <span className={styles.yellow}>b</span>
              <span className={styles.blue}>e</span>
              <span className={styles.orange}>n</span>
              <span className={styles.purple}> </span>
              <span className={styles.purple}>S</span>
              <span className={styles.orange}>i</span>
              <span className={styles.purple}>l</span>
              <span className={styles.green}>k</span>
              <span className={styles.yellow}>e</span>
              <span className={styles.blue}>.</span>
                
              <span className={styles.orange}> </span>
              <br></br>
              <span className={styles.purple}>D</span>
              <span className={styles.purple}>i</span>
              <span className={styles.green}>g</span>
              <span className={styles.yellow}>i</span>
              <span className={styles.blue}>t</span>
              <span className={styles.orange}>a</span>
              <span className={styles.purple}>l</span>
              <span className={styles.purple}> </span>
              <span className={styles.orange}>d</span>
              <span className={styles.purple}>e</span>
              <span className={styles.green}>s</span>
              <span className={styles.yellow}>i</span>
              <span className={styles.blue}>g</span>
              <span className={styles.orange}>n</span>
              <span className={styles.purple}>e</span>
              <span className={styles.purple}>r</span>
              <span className={styles.green}>,</span>
              <span className={styles.purple}> </span>
              <span className={styles.yellow}>c</span>
              <span className={styles.blue}>r</span>
              <span className={styles.orange}>e</span>
              <span className={styles.purple}>a</span>
              <span className={styles.purple}>t</span>
              <span className={styles.orange}>i</span>
              <span className={styles.purple}>e</span>
              <span className={styles.green}>v</span>
              <span className={styles.yellow}>e</span>
              <span className={styles.purple}> </span>
              <span className={styles.blue}>d</span>
              <span className={styles.orange}>e</span>
              <span className={styles.purple}>v</span>
              <span className={styles.purple}>e</span>
              <span className={styles.orange}>l</span>
              <span className={styles.purple}>o</span>
              <span className={styles.green}>p</span>
              <span className={styles.yellow}>e</span>
              <span className={styles.blue}>r</span>
              <span className={styles.purple}> </span>
              <span className={styles.orange}>e</span>
              <span className={styles.purple}>n</span>
              <span className={styles.purple}> </span>
              <span className={styles.purple}>b</span>
              <span className={styles.orange}>o</span>
              <span className={styles.purple}>v</span>
              <span className={styles.green}>en</span>
              <span className={styles.yellow}>a</span>
              <span className={styles.blue}>l</span>
              <span className={styles.purple}> </span>
              <span className={styles.orange}>g</span>
              <span className={styles.purple}>e</span>
              <span className={styles.purple}>w</span>
              <span className={styles.orange}>o</span>
              <span className={styles.purple}>o</span>
              <span className={styles.green}>n</span>
              <span className={styles.purple}> </span>
              <span className={styles.purple}>r</span>
              <span className={styles.yellow}>o</span>
              <span className={styles.blue}>s</span>
              <span className={styles.purple}> </span>
              <span className={styles.yellow}>en</span>
              <span className={styles.purple}> </span>
              <span className={styles.purple}>e</span>
              <span className={styles.purple}>n</span>
              <span className={styles.orange}>e</span>
              <span className={styles.purple}>r</span>
              <span className={styles.green}>gi</span>
              <span className={styles.yellow}>e</span>
              <span className={styles.blue}>k</span>
            </p>
          </div>
          <Link to={`/projects/`} className={styles.buttonProject}>
            <p>Ontdek mijn werk</p>
            <svg className={styles.buttonSvg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" aria-hidden="true"><polygon points="9.48 14.5 8.42 13.44 13.86 8 8.42 2.56 9.48 1.5 15.98 8 9.48 14.5"/><rect x="0" y="7.25" width="14.92" height="1.5"/></svg>
          </Link>
          <div className={styles.weetjesgenerator}>
            <Weetjesgenerator ></Weetjesgenerator>
          </div>
        </main>
        <div>
          <Footer />  
        </div>

 
      </div>
    </div>
  )}

/**
 * Head export to define metadata for the page
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
